import { AuthMethod } from '@hubcms/domain-auth';
import { GtmCode } from '@hubcms/domain-tracking';

export { enGB as dateFnsLocale } from 'date-fns/locale/en-GB';
export const locale = 'en-GB';
export const timeZone = 'Europe/Luxembourg';
export const archiveTitle = 'Archive';
export const gtmCodes: GtmCode[] = [{ code: 'GTM-MJWKBXZ', isParent: true }, { code: 'GTM-K2P93RR' }];
export const brandCode = 'lt';
export const adunitBrandCode = 'Luxtimes';
export const b2bUrlBrandCode = 'luxtimes';
export const b2bDMPMetaTagBrandCode = 'stp';
export const footerLogo = 'brand-main';
export const headerDateFormat = 'EEEE, MMMM d yyyy';
export const isHeroBlockMailIconInverted = false;
export const hasDividerInResultListLg = true;
export const loginLabel = 'Login';
export const logoutLabel = 'Logout';
export const authorPrefix = 'authors/';
export const searchPrefix = 'search/';
export const tagsPrefix = 'tag/';
export const tagsb2bPrefix = 'sponsored/';
export const logoSlug = 'brand-square-main';
export const logoVersion = '4.8.0';
export const authMethod: AuthMethod = 'oauth';
export const weatherSectionPage = '/weather/';
export const cimIdentifier = 'css1NMcpwZt2maOBK2hHcPWo.u1FlbtRnf5xVl77rof.07';
export const cimLanguage = 'EN';
export const tickarooClientId = '64772af30bfac400a065fb8b';
export const heroBlockIntroProps = {
  size: 'lg',
  weight: 'strong',
};
export const hasPullQuoteIcon = false;
export const domain = 'luxtimes.lu';
export const enablePersonalizedArticleList = true;
export const subscriptionButtonAppearance = 'secondary';
export const enableGrapeshot = true;
export const articleLayoutVersion = 'v2'; // articleV1 param
